.calendar_row {
  min-height: 60px;
  max-height: 18%;
  height: 150px;
}

.calendar_box_div {
  border: solid thin;
  /* border-right: solid thin;
    border-bottom: solid thin; */
  width: 14.2857%;
  position: relative;
}

.calendar_box_date {
  text-align: center;
  padding: 0px;
}

.calendar_box_left_over {
  width: calc(100% - 3px);
  border-radius: 3px;
  position: absolute;
  bottom: 0px;
  font-size: 90%;
  white-space: nowrap;
  overflow: hidden;
}
.calendar_box_row_negative {
  background-color: rgba(255,0,0,.6);
}
.calendar_box_row_positive {
  background-color: rgba(0,255,0,.6);
}

/* TODO try and make it a circle */

.calendar_box_date_circle {
  padding: 6px;
  display: inline-block;
  border-radius: 6px;
  vertical-align: middle;
  background-color: rgba(1, 1, 1, 0.404);
  color: white;
}
.calendar_box_date_circle_today {
  padding: 6px;
  display: inline-block;
  border-radius: 6px;
  vertical-align: middle;
  background-color: rgb(0, 0, 255);
  color: white;
}

/* only for week days */

.calendar_box_week_day {
  border: none;
  width: 14.2857%;
  position: relative;
}
